import './App.css';
import Index from './pages/Index'

function App() {
  return (
    <div>
      <Index />
    </div>
  );
}

export default App;
